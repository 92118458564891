import React, { Component } from 'react';
import AOS from 'aos';
import CountUp from 'react-countup';

import { Layout, SEO, Image, HeroBanner, CustomersSlider, TimeLine } from '../../components';
import { validateArray, validateSingleImage, validateRTE } from '../../components/utilities/functions';

import './index.scss';

class WhoWeAreTemp extends Component {

	state = {
		updateCount: false
	}

	componentDidMount() {
		AOS.init({
			duration: 1500,
			disable: 'mobile',
			once: true
		});

		if (typeof window !== "undefined") {
			window.addEventListener("scroll", this.handleScroll);
		}
	}

	handleScroll = () => {
		const currentScrollPos = typeof window !== "undefined" && window.pageYOffset;
		const stickyContainer = document.getElementById("sticky-container");
		if (
			stickyContainer
			&& stickyContainer.offsetTop &&
			currentScrollPos >= (stickyContainer.offsetTop - window.innerHeight) && !this.state.updateCount
		) {
			this.setState({
				updateCount: true
			});
		}
	};

	render() {

		const { pageContext } = this.props;
		const { uid = "", locale = "", hero_section, url, seo, company_history, customers_section, commitment_section, visionaries_banner, leadership_section, leading_innovation } = (pageContext.node) || {};

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		const leadershipCards = (cardlist) => (
			validateArray(cardlist) &&
			<div className='leadership-card-row'>
				{
					cardlist.map((item, index) =>
						<div className='leadership-card' key={index}>
							{
								item.image &&
								<div className='leader-image'>
									<Image
										className='img-100'
										data={{ image: item.image, image_alt: item.name }}
										resizeWidth={310}
									/>
								</div>
							}
							{
								(item.name || item.designation) &&
								<div className='card-content'>
									{
										item.name &&
										<h6 className='card-title'>{item.name}</h6>
									}
									{
										item.designation &&
										<span className='card-role'>{item.designation}</span>
									}
								</div>
							}
						</div>
					)
				}
			</div>
		);

		return (

			<Layout className='whoWeAre-wrapper animation-wrapper' pageURL={url}>
				<SEO pageURL={url} {...seo} extension={extension}/>
				{
					hero_section &&
					<HeroBanner
						image={hero_section.image}
						heading={hero_section.heading}
						imageAlt={hero_section.image_alt}
						hasOverlayBG={true}
					/>
				}

				{
					customers_section &&
					<div className='leadership-section box-100 text-center'>
						<div className='container'>
							<div className='col-12 col-768-9 mx-auto'>
								{
									customers_section.heading &&
									<h2 className='title-lineover'>{customers_section.heading}</h2>
								}
								{
									customers_section.description &&
									<div className='p__body1' dangerouslySetInnerHTML={{ __html: customers_section.description }} />
								}
							</div>
							{
								validateArray(customers_section.customer_logos) &&
								<div className='customer-logos-section'>
									<CustomersSlider
										data={customers_section.customer_logos}
										hasHover
										className='customers-slider'
										sliderSpeed={customers_section.slider_speed}
									/>
								</div>
							}
						</div>
					</div>
				}
				{
					company_history &&
					<>
						{/* for counter animation */}
						<div className='bg-gray comp-history-section' >
							<div className='container'>
								{
									company_history.heading &&
									<h2 className='title-lineover'>{company_history.heading}</h2>
								}
								{
									company_history.description &&
									<div className='p__body1 text-center' dangerouslySetInnerHTML={{ __html: company_history.description }} />
								}
								<div id="sticky-container" />
								<div className='highlights-wrapper row'>
									{
										company_history.company_info.map((item, index) =>
											<div data-col="4" className='highl-col col-12 col-768-6 col-992-3' key={index}>
												{
													item.count &&
													<h4 className='highl-count'>
														<CountUp
															delay={(item.count_value && item.count_value < 100 ? 1 : 0)}
															duration={(item.count_value && item.count_value <= 1000 ? 2.8 : 2)}
															start={(item.count_value && item.count_value <= 30 ? 100 : 0)}
															end={(this.state.updateCount ? item.count_value : 1000)}
															suffix={item.count_suffix && item.count_suffix}
															separator=','
														/>
													</h4>
												}
												{
													item.heading &&
													<h3 dangerouslySetInnerHTML={{ __html: item.heading }} />
												}
											</div>
										)
									}
								</div>
							</div>
						</div>
					</>
				}

				<section className='box-100'>
					<div className='container'>
						{
							validateArray(commitment_section) &&
							commitment_section.map((item, index) =>
								<div className='comm-content-block text-center' key={index}>
									{
										item.heading &&
										<h2 className='title-lineover'>{item.heading}</h2>
									}
									{
										item.description &&
										<div className='p__body1' dangerouslySetInnerHTML={{ __html: item.description }} />
									}
								</div>
							)
						}
						{
							leading_innovation &&
							<div className='text-center innovation-section'>
								{
									leading_innovation.heading &&
									<h2 className='title-lineover'>{leading_innovation.heading}</h2>
								}
								{
									leading_innovation.description &&
									<div className='p__body1' dangerouslySetInnerHTML={{ __html: leading_innovation.description }} />
								}
								{
									validateSingleImage(leading_innovation.logo) &&
									<Image
										className='timeline-logo'
										resizeWidth={200}
										width='200'
										data={{ image: leading_innovation.logo, image_alt: leading_innovation.image_alt }}
									/>
								}
								{
									validateArray(leading_innovation.raw_journey) &&
									<TimeLine data={leading_innovation.raw_journey[0]} />
								}
							</div>
						}
					</div>
				</section>
				{
					visionaries_banner &&
					<HeroBanner
						image={visionaries_banner.image}
						imageAlt={visionaries_banner.image_alt}
						heading={visionaries_banner.heading}
						hasContainer={false}
						h1Type={false}
					/>
				}
				{
					leadership_section &&
					<div className='leadership-section box-100 text-center'>
						<div className='container'>
							{
								leadership_section.heading &&
								<h2 className='title-lineover'>{leadership_section.heading}</h2>
							}
							{
								validateRTE(leadership_section.description) &&
								<div className='p__body1' dangerouslySetInnerHTML={{ __html: leadership_section.description }} />
							}
							{
								leadershipCards(leadership_section.leadership_cards_level_1)
							}
							{
								leadershipCards(leadership_section.leadership_cards_level_2)
							}
						</div>
					</div>
				}


				{/* {
                    technologies &&
                    <div className='technologies box-100 text-center'>
                        <div className='container container--980'>
                            {
                                technologies.heading &&
                                <h2 className='title-lineover'>{technologies.heading}</h2>
                            }
                            {
                                technologies.description &&
                                <div className='p__body1' dangerouslySetInnerHTML={{ __html: technologies.description }} />
                            }
                            {
                                validateArray(technologies.list) &&
                                <div className='serv-offered-row'>
                                    {
                                        technologies.list.map((item, index) =>
                                            item.logo &&
                                            item.logo.url &&
                                            <div className='tech-icon-image' key={index}>
                                                <Image data={{ image: item.logo, image_alt: item.name }} />
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                } */}

			</Layout>
		)
	}
}

export default WhoWeAreTemp;
